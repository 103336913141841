import axios from 'axios';
import { llmConectionsConfig } from '../config';
import { addLLMDataToChatAssistant } from '../services/ChatAssistantService';

const BASE_URL = llmConectionsConfig.baseURL;

export const createLLMAssitant = async (apiKey, modelProvider, assistantName, prompt, model) => {
  const url = `${BASE_URL}${modelProvider}/createAssitant`;
  const body = { apiKey, assistantName, prompt, model };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result createLLMAssitant', result);

  return result;
};

export const updateLLMAssitant = async (apiKey, modelProvider, assistantName, assistantId, prompt, model) => {
  const url = `${BASE_URL}${modelProvider}/updateAssitant`;
  const body = { apiKey, assistantName, assistantId, prompt, model };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result updateLLMAssitant', result);

  return result;
};

export const deleteLLMAssitant = async (apiKey, modelProvider, assistantId) => {
  const url = `${BASE_URL}${modelProvider}/deleteAssistant`;
  const body = { apiKey, assistantId };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result deleteLLMAssitant', result);

  return result;
};

export const createLLMThread = async (apiKey, modelProvider) => {
  const url = `${BASE_URL}${modelProvider}/createThread`;
  const body = { apiKey };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result createLLMThread', result);

  return result;
};

export const addLLMMessageToThread = async (apiKey, modelProvider, threadId, message) => {
  const url = `${BASE_URL}${modelProvider}/addMessageToThread`;
  const body = { apiKey, threadId, message };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result addLLMMessageToThread', result);

  return result;
};

export const createLLMRun = async (apiKey, modelProvider, assistantId, threadId) => {
  const url = `${BASE_URL}${modelProvider}/createARun`;
  const body = { apiKey, assistantId, threadId };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result createLLMRun', result);

  return result;
};

export const checkRunStatus = async (apiKey, modelProvider, threadId, runId) => {
  const url = `${BASE_URL}${modelProvider}/checkRunStatus`;
  const body = { apiKey, threadId, runId };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result checkRunStatus', result);

  return result;
};

export const getAssistantAnswer = async (apiKey, modelProvider, threadId) => {
  const url = `${BASE_URL}${modelProvider}/getAssistantAnswer`;
  const body = { apiKey, threadId };
  const headers = {};
  const result = await axios.post(url, body, { headers });
  // console.log('result getAssistantAnswer', result);

  return result;
};

export const createLLMStreamingRun = async (apiKey, modelProvider, assistantId, threadId) => {
  const url = `${BASE_URL}${modelProvider}/createAStreamingRun`;
  const body = { apiKey, assistantId, threadId };
  const headers = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
  // console.log('result createLLMRun', result);

  return response.body.getReader();
};

const hasAttrCreated = (attr) => Boolean(attr);

export const handleSendMessageToAssistant = async (
  companyId,
  chatAssistant,
  currentConnection,
  newMessage,
  handleUpdateAssistansState
) => {
  // console.log('chatAssistant', chatAssistant);
  // console.log('currentConnection', currentConnection);

  const apiKey = currentConnection.token;
  const modelProvider = currentConnection.modelProviderId;
  const { assistantName } = chatAssistant;
  const { prompt, firstMessage } = chatAssistant;
  const messagePrompt = `${firstMessage}, ${prompt}`;
  const model = currentConnection.modelId;

  let llm = chatAssistant?.llm || {};
  let needUpdateLLM = false;
  // const updateFlag = () => {
  //   needUpdateLLM = true;
  // };

  try {
    const isAssistantCreated = hasAttrCreated(llm.assistantId);
    // console.log('isAssistantCreated', isAssistantCreated);
    if (!isAssistantCreated) {
      const assistantResult = await createLLMAssitant(apiKey, modelProvider, assistantName, messagePrompt, model);
      // console.log('assistantResult', assistantResult);

      if (assistantResult.data.code >= 200 && assistantResult.data.code < 300) {
        llm = { ...llm, assistantId: assistantResult.data.id };
        // console.log('llm assistant', llm);

        needUpdateLLM = true;
      }
    }

    const isThreadCreated = hasAttrCreated(llm.threadId);
    // console.log('isThreadCreated', isThreadCreated);

    if (!isThreadCreated) {
      const threadResult = await createLLMThread(apiKey, modelProvider);
      // console.log('threadResult', threadResult);

      if (threadResult.data.code >= 200 && threadResult.data.code < 300) {
        llm = { ...llm, threadId: threadResult.data.id };
        // console.log('llm thread', llm);
        needUpdateLLM = true;
      }
    }

    if (needUpdateLLM) {
      await addLLMDataToChatAssistant(companyId, chatAssistant.id, llm);
      // actualizar el estado del asistente
      if (handleUpdateAssistansState) handleUpdateAssistansState({ ...chatAssistant, llm });
    }

    const messageResult = await addLLMMessageToThread(apiKey, modelProvider, llm.threadId, newMessage);
    return { messageResult, llm };
    // console.log('messageResult', messageResult);

    // const isRunCreated = hasAttrCreated(llm.runId);
    // console.log('isRunCreated', isRunCreated);

    // if (!isRunCreated) {
    // el run no se guarda
    // probando a partir de aqui ///////////////////////

    /*
    const runResult = await createLLMRun(apiKey, modelProvider, llm.assistantId, llm.threadId);
    // console.log('runResult', runResult);

    let runId = '';
    if (runResult.data.code >= 200 && runResult.data.code < 300) {
      llm = { ...llm, runId: runResult.data.id };
      runId = runResult.data.id;
      // console.log('llm run', llm);
      // needUpdateLLM = true;
    }
    // }

    const checkRunResult = await checkRunStatus(apiKey, modelProvider, llm.threadId, runId);
    // console.log('checkRunResult', checkRunResult);

    // if(checkRunResult)
    const answerResult = await getAssistantAnswer(apiKey, modelProvider, llm.threadId);
    console.log('answerResult', answerResult);
    // const answer = answerResult.answer || '';
    // console.log('answer', answer);

    // console.log('llm xd', llm);
    return answerResult.data; */
  } catch (error) {
    // console.log('error.response', error?.response);
    console.error(error);
    return { code: 400, error: error?.response };
  }
};

export const executeRun = async (currentConnection, llm) => {
  const apiKey = currentConnection.token;
  const modelProvider = currentConnection.modelProviderId;

  return createLLMStreamingRun(apiKey, modelProvider, llm.assistantId, llm.threadId);
};
