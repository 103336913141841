import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'es',
  
    icon: '/static/icons/ic_flag_es.svg',
  },
  {
    value: 'en',
  
    icon: '/static/icons/ic_flags_us.svg',
  },
  // {
  //   value: 'de',
  //   label: 'German',
  //   icon: '/static/icons/ic_flag_de.svg',
  // },
  // {
  //   value: 'fr',
  //   label: 'French',
  //   icon: '/static/icons/ic_flag_fr.svg',
  // },
];

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || {};

  const handleChangeLanguage = (newlang) => {
    console.log('Cambiando idioma a:', newlang);
    i18n.changeLanguage(newlang);
  };
  useEffect(() => {
    // Aquí puedes hacer algo cuando cambie el idioma
    console.log('Idioma actual:', currentLang.value);
  }, [currentLang]);

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
