import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
// utils
// import axios from '../../utils/axios';
//
// import { dispatch } from '../store';
import {
  addParticipantIntoCard,
  changeColumnOrder,
  changeColumns,
  createBoard,
  createFamilyColumn,
  createNoteConversation,
  createProjectCard,
  deleteAllFamily,
  deleteCard,
  deleteFamilyColumn,
  getCurrentBoard,
  getCurrentConversation,
  sendNote,
  updateFamilyColumn,
  updateFamilyList,
} from '../../services/ProjectService';
import { setCurrentCompany } from './company';
import { addPointsToUser,addPointsToCompany} from '../../services/RatingsService';

// ----------------------------------------------------------------------

// function objFromArray(array, key = 'id') {
//   console.log('array', array);
//   if (Object.keys(array).length === 0) {
//     return [];
//   }
//   return array?.reduce((accumulator, current) => {
//     accumulator[current[key]] = current;
//     return accumulator;
//   }, {});
// }

const initialStateConversation = () => ({
  id: '',
  messages: [],
  participants: [],
  type: 'ONE_TO_ONE',
  unreadCount: 0,
});

export const initialState = {
  isLoading: false,
  error: null,
  id: null,
  usersList: [],
  clientsList: [],
  currentCard: {},
  currentBoard: {
    familyId: '',
    cards: {},
    columns: {
      cero: {
        cardIds: [],
        id: 'cero',
        name: 'Pre-Factibilidad',
      },
      one: {
        cardIds: [],
        id: 'one',
        name: 'Diseño',
      },
      two: {
        cardIds: [],
        id: 'two',
        name: 'Desarrollo',
      },
    },
    columnOrder: ['cero', 'one', 'two'],
    notes: [],
  },
  conversation: { id: '', messages: [], participants: [], type: 'ONE_TO_ONE', unreadCount: 0 },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    setBoard(state, action) {
      state.currentBoard = action.payload;

      // state.isLoading = false;
      // const board = action.payload;
      // const cards = objFromArray(board?.cards);
      // const columns = objFromArray(board?.columns);
      // const columnOrder = board?.columnOrder;
      // state.currentBoard = {
      //   cards,
      //   columns,
      //   columnOrder,
      // };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      // state.isLoading = false;
      state.currentBoard.columns = {
        ...state.currentBoard.columns,
        [newColumn.id]: newColumn,
      };
      state.currentBoard.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.currentBoard.columns = columns;
    },

    persistColumn(state, action) {
      state.currentBoard.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId, currentBoard } = action.payload;

      // console.log('card', card);

      if (!currentBoard.cards) {
        state.currentBoard = {
          ...currentBoard,
          cards: {},
        };
      }
      if (!currentBoard.columns[columnId].cardIds) {
        state.currentBoard = {
          ...state.currentBoard,
          columns: {
            ...state.currentBoard.columns,
            [columnId]: {
              ...state.currentBoard.columns[columnId],
              cardIds: [],
            },
          },
        };
      }

      state.currentBoard.cards[card.id] = card;
      state.currentBoard.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );
      state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.currentBoard.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.currentBoard.columns[columnId];

      state.isLoading = false;
      state.currentBoard.columns = omit(state.currentBoard.columns, [columnId]);
      state.currentBoard.cards = omit(state.currentBoard.cards, [...deletedColumn.cardIds]);
      state.currentBoard.columnOrder = state.currentBoard.columnOrder.filter((c) => c !== columnId);
    },

    // clear board
    clear(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT BOARD
    setCurrentBoard(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT CARD
    setCurrentCard(state, action) {
      state.currentCard = action.payload;
    },

    // SET USERS LIST
    setUsersList(state, action) {
      state.usersList = action.payload;
    },

    // SET USERS LIST
    setClientsList(state, action) {
      state.clientsList = action.payload;
    },

    // SET FAMILY LIST
    // updateFamily(state, action) {
    //   state.currentBoard.familyList = action.payload;
    // },

    setCard(state, action) {
      const { name, element, card } = action.payload;
      state.currentBoard.cards[card.id][name] = element;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;
      // console.log('getConversationSuccess conversation', conversation);
      const newConversation = {
        ...conversation,
        messages: conversation.messages || [],
      };
      state.conversation = newConversation;

      // if (conversation) {
      //   state.conversations.byId[conversation.id] = conversation;
      //   state.activeConversationId = conversation.id;
      //   if (!state.conversations.allIds.includes(conversation.id)) {
      //     state.conversations.allIds.push(conversation.id);
      //   }
      // } else {
      //   state.activeConversationId = null;
      // }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const { conversation, card } = action.payload;
      // console.log('onSendMessage conversation', conversation);
      const { messageId, message, contentType, attachments, createdAt, senderId } = conversation; // conversationId

      const newMessage = {
        ...(contentType === 'attachment' ? conversation.file : null),
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      const messages = state.conversation.messages || [];
      const newMessages = [...messages, newMessage];
      // state.conversation.messages = newMessages;

      const newConversation = {
        ...conversation,
        messages: newMessages,
      };

      state.conversation = newConversation;

      state.currentBoard.cards[card.id].conversation = newConversation;
      console.log('newConversation xdddxd', newConversation);
      // setCard({ card, name: 'conversation', element: newConversation });
      // console.log('state.conversation', state.conversation.messages);
      // state.conversation.messages.push(newMessage); // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    // SET NOTE CONVERSATION
    setNoteConversation(state, action) {
      const { conversation } = action.payload;
      state.conversation = conversation;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { setBoard, createColumnSuccess, setCurrentBoard, setUsersList, setClientsList, setCard } = actions;

// ----------------------------------------------------------------------

export function getNewBoard() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.get('/api/project/board');
    // const board = { cards: {}, columns: {}, columnOrder: [] };
    dispatch(setBoard(initialState.currentBoard));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function fetchBoardProject({ branchId, boardId, familyId }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // console.log('boardId', boardId);
    let board = initialState.currentBoard;
    if (boardId) {
      // console.log('1');
      board = await getCurrentBoard(branchId, familyId);
      // console.log('board', board);
      if (!board) {
        board = await createBoard(branchId, initialState.currentBoard, familyId);
      }
    } else {
      // console.log('2');
      board = await getCurrentBoard(branchId, familyId);
      // console.log('board', board);
      if (!board) {
        // console.log('3');
        board = await createBoard(branchId, initialState.currentBoard, familyId);
      }
    }
    // console.log('setBoard board', board);
    dispatch(setBoard(board));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function createColumn({ branchId, currentBoard, newColumn }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.post('/api/project/columns/new', newColumn);

    const columnId = await createFamilyColumn(branchId, currentBoard, newColumn);
    dispatch(createColumnSuccess({ ...newColumn, id: columnId }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function updateColumn({ branchId, currentBoard, column, newName, handleShowMessage }) {
  return async (dispatch) => {
    await updateFamilyColumn(branchId, currentBoard, column, newName);
    dispatch(slice.actions.updateColumnSuccess({ ...column, name: newName }));

    handleShowMessage();
    // dispatch(slice.actions.startLoading());
    // try {
    //   const response = await axios.post('/api/project/columns/update', {
    //     columnId,
    //     updateColumn,
    //   });
    //   dispatch(slice.actions.updateColumnSuccess(response.data.column));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn({ branchId, currentBoard, columnId, handleShowMessage }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());

    await deleteFamilyColumn(branchId, currentBoard, columnId);
    dispatch(slice.actions.deleteColumnSuccess({ columnId }));

    handleShowMessage();
    // try {
    //   await axios.post('/api/project/columns/delete', { columnId });
    //   dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function clearBoard() {
  return async (dispatch) => {
    dispatch(slice.actions.clear(initialState.currentBoard));
  };
}

// ----------------------------------------------------------------------

export function persistColumn({ newColumnOrder, branchId, currentBoard }) {
  return async (dispatch) => {
    await changeColumnOrder(branchId, currentBoard, newColumnOrder);
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard({ columns, branchId, currentBoard, start, finish, draggableId }) {
  return async (dispatch) => {
    const card = currentBoard.cards[draggableId];
    await changeColumns(branchId, columns, start, finish, card, currentBoard.familyId);
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId, branchId, currentBoard, user }) {
  return async (dispatch) => {
    const { currentCompany, currentBranch } = useSelector((state) => state.company);
    const { cardId, consecutiveNumber } = await createProjectCard(branchId, currentBoard, card, columnId);
    // console.log('cardId', cardId);
    const newCard = {
      ...card,
      id: cardId,
      consecutiveNumber,
    };
    dispatch(slice.actions.addTask({ card: newCard, columnId, currentBoard }));

    await addPointsToUser('CRMPL', 'create', user, consecutiveNumber);
    await addPointsToCompany('CRMPL', 'create', { ...currentCompany, id: currentCompany.id },consecutiveNumber);
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId, branchId, currentBoard, user, card }) {
  return async (dispatch) => {
    const { currentCompany, currentBranch } = useSelector((state) => state.company);
    await deleteCard(branchId, currentBoard, cardId, columnId);
    dispatch(slice.actions.deleteTask({ cardId, columnId }));

    await addPointsToUser('CRMPL', 'delete', user, card.consecutiveNumber);
    await addPointsToCompany('CRMPL', 'delete', { ...currentCompany, id: currentCompany.id },card.consecutiveNumber);
  };
}

// export function addElementInListCard({ name, element, currentBoard, card }) {
//   console.log('currentBoard', currentBoard);
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }

// export function setElementInCard({ name, element, currentBoard, card }) {
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }

export function updateFamily({ newFamilyList, companyId, currentCompany }) {
  return async (dispatch) => {
    await updateFamilyList(newFamilyList, companyId);
    // console.log('cardId', cardId);
    // dispatch(slice.actions.updateFamily(newFamilyList));
    dispatch(setCurrentCompany({ ...currentCompany, familyList: newFamilyList }));

    // dispatch(slice.actions.addFamily({ card: newCard, columnId, currentBoard }));
  };
}

export function deleteFamily({ newFamilyList, companyId, branchId, currentCompany, currentBoard }) {
  return async (dispatch) => {
    // console.log(
    //   'newFamilyList, companyId, branchId, currentCompany, currentBoard ',
    //   newFamilyList,
    //   companyId,
    //   branchId,
    //   currentCompany,
    //   currentBoard
    // );
    await updateFamilyList(newFamilyList, companyId);
    // console.log('cardId', cardId);
    // dispatch(slice.actions.updateFamily(newFamilyList));
    await deleteAllFamily(branchId, currentBoard);

    dispatch(setCurrentCompany({ ...currentCompany, familyList: newFamilyList }));
    // dispatch(slice.actions.addFamily({ card: newCard, columnId, currentBoard }));
  };
}

// CONVERSATION

export function getConversation(branchId, conversationKey, senderId, familyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/chat/conversation', {
      //   params: { conversationKey: 'sofia.funk' }, // params: { conversationKey },
      // });
      // console.log('conversations xd', response.data.conversation);
      // dispatch(slice.actions.getConversationSuccess(response.data.conversation));

      // let conversation = initialStateConversation(senderId); // .currentBoard;
      // console.log('1');
      let conversation = await getCurrentConversation(branchId, conversationKey, familyId);
      // console.log('c', conversation);
      if (!conversation) {
        // console.log('3');
        conversation = await createNoteConversation(
          branchId,
          initialStateConversation(senderId),
          conversationKey,
          familyId
        );
        // console.log('conversation creada', conversation);
      }
      //  else {
      //   conversation = await getCurrentConversation(conversationKey);
      //   // console.log('board', board);
      //   if (!conversation) {
      //     conversation = await createNoteConversation(branchId, initialStateConversation(senderId), conversationKey);
      //   }
      // }
      // console.log('conversation XD', conversation);
      dispatch(slice.actions.getConversationSuccess(conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function onSendMessage({ conversation, card, branchId, familyId, profile }) {
  return async (dispatch) => {
    const { messageId, message, messages, contentType, attachments, createdAt, senderId } = conversation; // conversationId
    const newMessage = {
      ...(contentType === 'attachment' ? conversation.file : null),
      id: messageId,
      body: message,
      contentType,
      attachments,
      createdAt,
      senderId,
    };

    const newMessages = [...(messages || []), newMessage];

    await sendNote(branchId, card.id, familyId, newMessages);
    // comentado porque ya se usa el listening
    // dispatch(slice.actions.onSendMessage({ conversation, card }));

    const participants = conversation?.participants || [];

    const participantIds = participants.map((el) => el.id);
    const participantExists = participantIds.includes(profile.id);
    if (!participantExists) {
      const newParticipant = {
        address: profile.address || 'CR',
        avatar: profile.photoURL || '/static/user/user.png',
        email: profile.email || '',
        from: profile.phoneNumber || '',
        id: profile.id || '',
        uid: profile.id || '',
        lastActivity: new Date().toISOString(),
        name: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phoneNumber || '',
        role: 'ux designer',
        status: 'online',
        username: `${profile.firstName || ''} ${profile.lastName || ''}`,
      };
      const newParticipants = [...participants, newParticipant];
      console.log('newParticipants', newParticipants);
      await addParticipantIntoCard(branchId, card.id, familyId, newParticipants);
    }
  };
}

export function setNoteConversation({ conversation }) {
  return async (dispatch) => {
    dispatch(slice.actions.setNoteConversation({ conversation }));
  };
}
