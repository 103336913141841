import firebase from 'firebase/app';
import { editUserPointsRating, getUserPointsRating,editCompanyPointsRating,getCompanyPointsRating } from './UserService';

const RATINGS_BASE_URL = 'ratings';
const USER_LOG_BASE_URL = 'rating_logs_user';
const COMPANY_LOG_BASE_URL = 'rating_logs_company';

export async function getAllRatings() {
  return firebase
    .database()
    .ref()
    .child(`${RATINGS_BASE_URL}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getAllRatingscompany() {
  return firebase
    .database()
    .ref()
    .child(`${COMPANY_LOG_BASE_URL}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getRatingById(moduleType) {
  return firebase
    .database()
    .ref()
    .child(`${RATINGS_BASE_URL}/${moduleType}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

export const createNewRating = async (rating) => {
  try {
    const rootRef = firebase.database().ref('/');
    const ratingRef = rootRef.child(`${RATINGS_BASE_URL}/${rating.moduleType}`);
    const newRating = await ratingRef.set(rating);

    // const updates = {};
    // updates[`/${RATINGS_BASE_URL}/${rating.moduleType}/${newRating.key}/id`] = newRating.key;
    // await firebase.database().ref().update(updates);

    return { id: newRating.key };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateRating = async (rating) => {
  const updates = {};
  updates[`${RATINGS_BASE_URL}/${rating.moduleType}`] = rating;

  await firebase.database().ref().update(updates);
};

export const deleteRating = async (moduleType) => {
  const ratingRef = firebase.database().ref(`/${RATINGS_BASE_URL}/${moduleType}`);
  await ratingRef.remove();
};

export async function getUserLogs(uid) {
  return firebase
    .database()
    .ref()
    .child(`${USER_LOG_BASE_URL}/${uid}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const createUserLog = async (uid, newLog) => {
  const rootRef = firebase.database().ref('/');
  const ratingRef = rootRef.child(`${USER_LOG_BASE_URL}/${uid}`);
  await ratingRef.push(newLog);
};

export const createCompanyLog = async (id, newLog) => {
  const rootRef = firebase.database().ref('/');
  const currentDate = new Date();

  // Formatear la fecha a 'YYYY/MM/DD HH:mm:ss' para la ruta diaria
  const formattedDailyDate = `${currentDate.getFullYear()}/${
    (currentDate.getMonth() + 1).toString().padStart(2, '0')}/${
    currentDate.getDate().toString().padStart(2, '0')} ${
    currentDate.getHours().toString().padStart(2, '0')}:${
    currentDate.getMinutes().toString().padStart(2, '0')}:${
    currentDate.getSeconds().toString().padStart(2, '0')}`;

  // Formatear la fecha a 'YYYY-MM' para la ruta mensual
  const formattedMonthlyDate = `${currentDate.getFullYear()}/${
    (currentDate.getMonth() + 1).toString().padStart(2, '0')}`;

  // Referencias para daily y monthly
  const dailyRef = rootRef.child(`${COMPANY_LOG_BASE_URL}/${id}/daily/${formattedDailyDate.replace(/\//g, '-').replace(' ', '_')}`);
  const monthlyRef = rootRef.child(`${COMPANY_LOG_BASE_URL}/${id}/monthly/${formattedMonthlyDate.replace(/\//g, '-')}`);

  // Obtener el valor existente para daily
  const dailySnapshot = await dailyRef.once('value');
  const existingDailyData = dailySnapshot.val();

  let updatedDailyPoints = newLog.newPoints;

  if (existingDailyData && existingDailyData.newPoints) {
    updatedDailyPoints += existingDailyData.newPoints;
  }

  // Actualizar el campo 'newPoints' para daily con el valor acumulado
  await dailyRef.update({
    newPoints: updatedDailyPoints
  });

  // Obtener el valor existente para monthly
  const monthlySnapshot = await monthlyRef.once('value');
  const existingMonthlyData = monthlySnapshot.val();

  let updatedMonthlyPoints = newLog.newPoints;

  if (existingMonthlyData && existingMonthlyData.newPoints) {
    updatedMonthlyPoints += existingMonthlyData.newPoints;
  }

  // Actualizar el campo 'newPoints' para monthly con el valor acumulado
  await monthlyRef.update({
    newPoints: updatedMonthlyPoints
  });
};






export const addPointsToUser = async (moduleType, action, user, documentId = '') => {
  const rating = await getRatingById(moduleType);
  // console.log('rating', rating);
  // console.log('rating[action]', rating[action]);
  if (rating && rating[action]) {
    const newPoints = Number(rating[action]);
    // console.log('newPoints', newPoints);
    // console.log('user', user);

    const pointsRating = await getUserPointsRating(user.uid);
    // console.log('pointsRating', pointsRating);

    const newPointsRating = pointsRating + newPoints;
    // console.log('newPointsRating', newPointsRating);

    await editUserPointsRating(user.uid, newPointsRating);

    const currentDate = new Date().toISOString();
    const newLog = {
      action,
      currentDate,
      moduleType,
      newPoints,
      documentId,
    };
    await createUserLog(user.uid, newLog);
  }
};

export const addPointsToCompany = async (moduleType, action, company = '') => {
  const rating = await getRatingById(moduleType);
  // console.log('rating', rating);
  // console.log('rating[action]', rating[action]);
  if (rating && rating[action]) {
    const newPoints = Number(rating[action]);
    // console.log('newPoints', newPoints);
    // console.log('user', user);

    const pointsRating = await getCompanyPointsRating(company.id);
    // console.log('pointsRating', pointsRating);

    const newPointsRating = pointsRating + newPoints;
    // console.log('newPointsRating', newPointsRating);

    await editCompanyPointsRating(company.id, newPointsRating);


    const newLog = {
      
      
     
      newPoints,
      
    };
    await createCompanyLog(company.id, newLog);
  }
};
