export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL,
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_REALTIME,
  authDomain: process.env.REACT_APP_FIREBASE_REALTIME_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_REALTIME_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_REALTIME_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_REALTIME_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_REALTIME_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_REALTIME_MEASUREMENT_ID,
};

export const emailJSConfig = {
  serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  userId: process.env.REACT_APP_EMAILJS_USER_ID,
  invoiceTemplateId: process.env.REACT_APP_EMAILJS_INVOICE_TEMPLATE_ID,
};

export const payPalConfig = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  secret: process.env.REACT_APP_PAYPAL_SECRET,
};
export const algolia = {
  aplication_Id: process.env.REACT_APP_APPLICATION_ID,
  apiKey: process.env.REACT_APP_SEARCH_API_KEY,
};

export const secretDeveloperCode = process.env.REACT_APP_SECRET_DEVELOPER_CODE;

export const haciendaConfig = {
  user: process.env.REACT_APP_USER_HACIENDA,
  password: process.env.REACT_APP_PASSWORD_HACIENDA,
};

export const minimalsConfig = {
  hostApiKey: process.env.REACT_APP_HOST_API_KEY,
};

export const linkPreviewConfig = {
  apiKey: process.env.REACT_APP_LINK_PREVIEW_API_KEY,
};

export const corsConfig = {
  corsOrigin: process.env.REACT_APP_CORS_ORIGIN,
  corsOrigin2: process.env.REACT_APP_CORS_ORIGIN2,
};

export const whatsappBackendConfig = {
  baseURL: process.env.REACT_APP_WHATSAPP_BASE_URL,
  qrBaseURL: process.env.REACT_APP_WHATSAPP_QR_BASE_URL,
};

export const llmConectionsConfig = {
  baseURL: process.env.REACT_APP_LLM_CONNECTIONS_BASE_URL,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};
